import {
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@mui/styles"
import { colors } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem 5.5rem",
    borderTop: "1px solid #C0C0C0",
    borderBottom: "1px solid #C0C0C0",

    marginTop: 50,

    [theme.breakpoints.between("md", "md")]: {
      padding: "1rem 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem",
    },
    "& .MuiGrid-item": {
      maxWidth: "none",
    },
  },
  logo: {
    width:'150px',
    height:'100px',
    // paddingBottom:"50px"
  },
  image: {
    marginLeft: "10px !important",
  },
  address: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: colors.purple["A400"],
  },
  linksHeading: {
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: "18px",
    lineHeight: "24px",
    color: colors.purple[500],
  },
  link: {
    color: "#18191F",
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  inputField: {
    background: "rgba(236, 185, 249,0.2)",
    borderRadius: "10px",
    border: "none !important",
    padding: "0.8rem 1rem",
  },
  resize: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#616161",
  },
  paymentLogo: {
    width: "50px",
    height: "35px",
  },
  copyRightContainer: {
    padding: "1.5rem",
  },
  copyrighText: {
    color: '#5EC198',
    textAlign: "center",
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: "14px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
     container
        justifyContent="space-between"
        spacing={3}
        className={classes.container}
     >
      <Grid
        item
        xs={12}
        md={4}
      >
            <img className={classes.logo} src="/static/logox.png" alt="sss"/>
         </Grid>  
        <Grid
         className={classes.copyRightContainer}
          item
          xs={12}
          md={4}
        >
        <Typography className={classes.copyrighText}>
          © 2023 KBM Engineering All Rights Reserved
        </Typography>
        </Grid>

        <Grid md={4} 
        xs={12}
        item
         >
          <Grid
            item
            container
            xs={4}
            md={3}
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item xs={2} style={{ position: "relative" }}>
              <img
                src="/static/Mastercard.png"
                alt="Master Card"
                className={classes.paymentLogo}
              />
            </Grid>
            <Grid item xs={2} style={{ position: "relative" }}>
              <img
                src="/static/Visa.png"
                alt="Visa"
                className={classes.paymentLogo}
              />
            </Grid>
            <Grid item xs={2} style={{ position: "relative" }}>
              <img
                src="/static/Stripe.png"
                alt="Stripe"
                className={classes.paymentLogo}
              />
            </Grid>
            <Grid item xs={2} style={{ position: "relative" }}>
              <img
                src="/static/PayPal.png"
                alt="PayPal"
                className={classes.paymentLogo}
              />
            </Grid>
          </Grid>
        </Grid>
      

</Grid>
);
};

export default Footer;
