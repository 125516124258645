import React from 'react'
import { makeStyles } from "@mui/styles"
import { Button,Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import Header from 'src/components/Header/Header';
import OurClientSpeaks from 'src/components/OurClientSpeaks';
// import HeroSection from 'src/components/HeroSection/HeroSection';
import Footer from 'src/layouts/Web/Components/Footer/Footer';
import {Grid,Typography} from '@mui/material';
import {AppBar,Toolbar,Link} from '@mui/material';
import MenuDrawer from 'src/layouts/Web/Components/Drawer/Drawer';
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    boxShadow: "0px 2px #E5E9F2",
    backgroundColor: "#ffffff",
  },
  DrawerflexGrow: {
    flexGrow: 2,
  },
  Drawersearch: {
    flexBasis: 300,
    height: 36,
    marginRight: "16px",
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    borderWidth: 2,
  },
  drawerTitle: {
    cursor: "pointer",
    width:'160px',
    height:'100px',
  },
  DrawerbuttonLogin: {
    backgroundColor: "white", 
    borderColor:"#5EC198",
    borderRadius:'30px',
    color: "#5EC198",
    marginRight:10,
    fontFamily: "Manrope, sans-serif",
    transition: 'background-color 0.3s ease-in-out',
  },
  DrawerbuttonHoverLogin: {
    '&:hover': {
      // backgroundColor: '#ecb9f9',
      color:'#9ed9c1'
    },
  },
  DrawerbuttonRegister: {
    backgroundColor:"#5EC198", 
    color: 'white',
    fontFamily: "Manrope, sans-serif",
    transition: 'background-color 0.3s ease-in-out',
    borderRadius:'30px'
  },
  DrawerbuttonHoverRegister: {
    '&:hover': {
      backgroundColor: '#9ed9c1',
    },
  },

  [theme.breakpoints?.down('sm')]: {
    drawerTitle: {
      // paddingLeft: "0em",
      width:'200px',
      height:'100px'
    },
    drawer: {
      paddingLeft: "100px",
      // height:'100px'
      // backgroundColor: "red",
    },
  },
  DrawermenuItems: {
    cursor: "pointer",
    paddingLeft: "12px",
    paddingRight: "12px",
    "& li": {
      display: "inline-block",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  section: {
    height: "100vh",
    backgroundColor:'#003f75'
  },
  section1Img: {
    width: "100%",
    height: "auto",
  },
  heroText: {
    fontSize: "3.5rem",
    lineHeight: "4rem",
    fontWeight: 600,
    color: "white",
    marginLeft:"30px",
    fontFamily: "Manrope, sans-serif",
    "& > span": {
      color: "#9A0FBF",
    },
  },
  tags: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0 2rem",
  },
  tagsDetail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
    fontFamily: "Manrope, sans-serif",
    fontWeight: 700,
    color: "#000000",
  },
  tagText: {
    color: "black",
  },
    bgColor: {
        backgroundColor: 'white',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:"column"
      },
      topLeftPolygon: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        borderTop:'300px solid #9A0FBF',
        borderRight: '300px solid transparent',
      },
      bottomRightPolygon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 0,
        height: 0,
        borderBottom:'300px solid #9A0FBF',
        borderLeft: '300px solid transparent',
      },
      iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:"100px",
        flexDirection:"column"
      },
      icon: {
        width: '100%',
        height: '70%', 
      },
      kbm:{
        color: 'white', 
        fontFamily:'Inter',
        fontWeight:"bold"
      },
      title: {
        color: 'black', 
        fontSize: '50px',
        fontFamily: "Manrope, sans-serif",
        [theme.breakpoints.down("sm")]: {
          fontSize: "25px",
        },
      },
      subHead:{
        color: 'black',
        fontSize:'18px',
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
      buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        marginTop:"80px",
        marginBottom:"80px"
      },
      buttonLogin: {
        backgroundColor: '#ffff', 
        color: '#5EC198',
        height:'70px',
        fontFamily: "Manrope, sans-serif",
        width:'200px',
        marginRight:'10px',
        borderRadius: '30px',
        cursor: 'pointer',
        fontSize: '20px',
        borderColor:"#5EC198",
        transition: 'background-color 0.3s ease-in-out',
        [theme.breakpoints.down("sm")]: {
          width: "150px",
        },
      },
      buttonHoverLogin: {
        '&:hover': {
          // backgroundColor: '#ecb9f9',
          color:'#9ed9c1'
        },
      },
      buttonRegister: {
        backgroundColor:'#5EC198', 
        height:'70px',
        width:'200px',
        borderRadius: '30px',
        fontFamily: "Manrope, sans-serif",
        cursor: 'pointer',
        fontSize: '20px',
        borderColor:"white",
        transition: 'background-color 0.3s ease-in-out',
        [theme.breakpoints.down("sm")]: {
          width: "150px",
        },
      },
      buttonHoverRegister: {
        '&:hover': {
          backgroundColor: '#9ed9c1',
          // color:'#430454'
        },
      },
}));

const LandingPage = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    const handleLogin = ()=>{
        navigate('/login')
    }
    const handleRegister = ()=>{
        navigate('/signup')
    }
    
  return (
    <>
    {/* <Header/> */}
    <AppBar className={clsx(classes.Drawerroot)}>
      <Toolbar>
        <div style={{ flexGrow: 0.1 }} />
        <Link to="/">
          <img className={classes.drawerTitle} src="/static/logox.png" alt="s"/>
        </Link>
        <div className={classes.DrawerflexGrow} />
        <Hidden smDown>
                <Button onClick={handleLogin} className={`${classes.DrawerbuttonLogin} ${classes.DrawerbuttonHoverLogin}`} variant="outlined">Login Now</Button>
                <Button onClick={handleRegister} className={`${classes.DrawerbuttonRegister} ${classes.DrawerbuttonHoverRegister}`} variant="contained">Register</Button>
        </Hidden>

        <Hidden mdUp className={classes.drawer}>
          <MenuDrawer />
        </Hidden>
        <div style={{ flexGrow: 0.2 }} />
      </Toolbar>
    </AppBar>
    <Grid container className={classes.bgColor}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <div className={classes.iconContainer}>
          <img src="/static/logox.png" alt="s"/>
          <Typography variant="h3" className={classes.title}>
          Connect, Trade, Exchange!
          </Typography>
          <Typography variant="span" className={classes.subHead}>
          Connect your clients. Anywhere!
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={handleLogin}
            className={`${classes.buttonLogin} ${classes.buttonHoverLogin}`}
          >
            Login Now
          </Button>
          <Button
            variant="contained"
            onClick={handleRegister}
            className={`${classes.buttonRegister} ${classes.buttonHoverRegister}`}
          >
            Register
          </Button>
        </div>
      </Grid>
    </Grid>
   {/* <HeroSection/> */}
   <Grid
      container
      spacing={2}
      className={classes.section}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Hidden mdUp>
          <Grid item xs={12} sm={7}>
            <img
              src={"/static/61765bg.png"}
              alt="avatar"
              className={classes.section1Img}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.heroText}>
        Trade with your clients, anywhere in the world, anytime!
          </Typography>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={6}>
          <img
            src={"/static/61765bg.png"}
            alt="avatar"
            className={classes.section1Img}
          />
        </Grid>
      </Hidden>
    </Grid>
   <Footer/>
   </>
  )
}

export default LandingPage