import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from "formik";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import currencyInstance from 'src/axios/currencyInstance';
import CancelIcon from '@mui/icons-material/Cancel';
import * as Yup from "yup";
import {
    FormControl,
    TextField,
    MenuItem,
    Select,
    Button,
    InputLabel,
    Typography,
    Input,
} from '@mui/material';
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "../../src/css/invoiceImage.css"
import { makeStyles } from "@mui/styles"
import PreviewImage from 'src/components/fileReader';
import invoiceInstance from "src/axios/invoiceInstance";
import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    input: {
        display: 'none',
    },
    previewContainer: {
        marginTop: theme.spacing(2),
    },
    previewImage: {
        maxWidth: '100%',
    }
}));


const UpdateInvoice = (props) => {
    const [currencies, setCurrencies] = useState([])
    const [invoices, setInvoices] = useState([])
    const classes = useStyles();
    let params = useParams();

    const fetchCurrencies = async () => {
        try {
            const res = await currencyInstance.get(`/`)
            const rates = res.data.currencies
            setCurrencies(rates)
        } catch (error) {
            console.log({ error })
        }
    }
    const fetchInvoice = async () => {
        try {
            const response = await invoiceInstance.get(`/invoice/${params.slug}`);
            const data = response.data.remitInvoices;
            setInvoices(data)
        } catch (error) {
            console.error({ error })
        }
    }
    useEffect(() => {
        fetchInvoice()
        fetchCurrencies()
    }, [])

    const InvoiceSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        phoneNo: Yup.string().required("Please enter your phone number"),
        ref: Yup.string(),
        address: Yup.string().required("Please provide your address"),
        currency: Yup.string().required("Currency is required"),
        amount: Yup.number().required("Amount is required").positive().integer(),
        selectedOption: Yup.string().required("Please Select a method"),
        iban: Yup.string().when('selectedOption', {
            is: 'IBAN',
            then: Yup.string().required("Iban is Required"),
            otherwise: Yup.string()
        }),
        accountNo: Yup.string().when('selectedOption', {
            is: 'AccountNumber',
            then: Yup.string().required("Account No. is Required"),
            otherwise: Yup.string()
        }),
        swift: Yup.string().when('selectedOption', {
            is: 'AccountNumber',
            then: Yup.string().required("Swift code is Required"),
            otherwise: Yup.string()
        }),
        image: Yup.mixed().required("Image Proof Required"),
        action: Yup.string()
    })


    const formik = useFormik({
        initialValues: {
            firstName: `${invoices.firstName}`,
            lastName: `${invoices.lastName}`,
            phoneNo: `${invoices.phoneNo}`,
            ref: `${invoices.ref}`,
            address: `${invoices.address}`,
            currency: `${invoices.currency}`,
            amount: `${invoices.amount}`,
            selectedOption: `${invoices.selectedOption}`,
            iban: `${invoices.iban}`,
            accountNo: `${invoices.accountNo}`,
            swift: `${invoices.swift}`,
            image: "",
            action: 'remittanceInvoice'
        },
        validationSchema: InvoiceSchema,
        onSubmit: async (values, { setErrors }) => {
            const formData = new FormData();
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            formData.append("phoneNo", values.phoneNo)
            formData.append("ref", values.ref)
            formData.append("address", values.address)
            formData.append("currency", values.currency)
            formData.append("amount", values.amount)
            formData.append("iban", values.iban)
            formData.append("accountNo", values.accountNo)
            formData.append("swift", values.swift)
            formData.append("action", values.action)
            formData.append("image", values.image)
            try {
                await submittedData(formData);
            } catch (err) {
                setErrors({ error: err.response.data.message });
            }
        },
    })


    const { errors, touched, number, handleSubmit, handleChange, values, getFieldProps, setFieldValue } = formik;

    const { submittedData } = props


    return (
        <>
            <React.Fragment>
                <Paper elevation={3} sx={{ marginRight: "1%", marginLeft: "1%" }}>
                    <Box sx={{ padding: 5 }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Payee Information
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="firstName"
                                            label="First Name"
                                            fullWidth
                                            required
                                            value={values.firstName}
                                            onChange={handleChange}
                                            {...getFieldProps("firstName")}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="lastName"
                                            label="Last Name"
                                            fullWidth
                                            required
                                            value={values.lastName}
                                            onChange={handleChange}
                                            {...getFieldProps("lastName")}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="phoneNo"
                                            label="Phone Number"
                                            fullWidth
                                            type='number'
                                            required
                                            value={values.phoneNo}
                                            onChange={handleChange}
                                            {...getFieldProps("phoneNo")}
                                            error={Boolean(touched.phoneNo && errors.phoneNo)}
                                            helperText={touched.phoneNo && errors.phoneNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="ref"
                                            label="Reference"
                                            fullWidth
                                            value={values.ref}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="address"
                                            label="Address"
                                            fullWidth
                                            multiline
                                            required
                                            rows={3}
                                            value={values.address}
                                            onChange={handleChange}
                                            {...getFieldProps("address")}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Account Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="currency-label"  {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                required
                                                helperText={touched.currency && errors.currency}>Select Currency</InputLabel>
                                            <Select
                                                labelId="currency-label"
                                                id="currency"
                                                value={values.currency}
                                                onChange={handleChange}
                                                {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                helperText={touched.currency && errors.currency}

                                            >
                                                {currencies.map((option) => (
                                                    <MenuItem key={option.name} value={option.sign}>
                                                        {option.sign}{"(" + option.name + ")"}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="amount"
                                            label="Enter Amount"
                                            fullWidth
                                            type="number"
                                            required
                                            value={values.amount}
                                            onChange={handleChange}
                                            {...getFieldProps("amount")}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="payment-method-label"
                                                {...getFieldProps("selectedOption")}
                                                required
                                                error={Boolean(touched.selectedOption && errors.selectedOption)}
                                                helperText={touched.selectedOption && errors.selectedOption}
                                            >Select Payment Method</InputLabel>
                                            <Select
                                                labelId="payment-method-label"
                                                id="selectedOption"
                                                value={values.selectedOption}
                                                onChange={handleChange}
                                                {...getFieldProps("selectedOption")}
                                                error={Boolean(touched.selectedOption && errors.selectedOption)}
                                                helperText={touched.selectedOption && errors.selectedOption}
                                            >
                                                <MenuItem value="IBAN">IBAN</MenuItem>
                                                <MenuItem value="AccountNumber">Account Number</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {values.selectedOption === 'IBAN' && (

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="iban"
                                                label="Enter IBAN"
                                                required
                                                fullWidth
                                                value={values.iban}
                                                onChange={handleChange}
                                                {...getFieldProps("iban")}
                                                error={Boolean(touched.iban && errors.iban)}
                                                helperText={touched.iban && errors.iban}
                                            />
                                        </Grid>
                                    )}
                                    {values.selectedOption === 'AccountNumber' && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="accountNo"
                                                    label="Enter Account Number"
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    value={values.accountNo}
                                                    onChange={handleChange}
                                                    {...getFieldProps("accountNo")}
                                                    error={Boolean(touched.accountNo && errors.accountNo)}
                                                    helperText={touched.accountNo && errors.accountNo}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="swift"
                                                    label="Enter Swift Code"
                                                    fullWidth
                                                    value={values.swift}
                                                    required
                                                    onChange={handleChange}
                                                    {...getFieldProps("swift")}
                                                    error={Boolean(touched.swift && errors.swift)}
                                                    helperText={touched.swift && errors.swift}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className={classes.input}
                                            id="image-upload"
                                            onChange={(e) => {
                                                setFieldValue("image", e.target.files[0])
                                            }}
                                        />
                                        <label htmlFor="image-upload">
                                            <Button
                                                id='btn'
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                startIcon={<CloudUploadIcon style={{ color: "white" }} />}
                                            >
                                                <Typography color="white">
                                                    Upload Slip
                                                </Typography>
                                            </Button>
                                            {errors.image ? <div style={{ color: 'red', fontSize: 'small' }}>{errors.image}</div> : null}
                                        </label>

                                        {values.image &&
                                            <>
                                                <PreviewImage file={values.image} />
                                            </>
                                        }

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box textAlign='center'>
                                            <Button type="submit" variant="contained" color="primary" >
                                                <Typography color="white">
                                                    Submit
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Paper>
            </React.Fragment >
        </>
    )
}

export default UpdateInvoice