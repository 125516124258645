// component
import Iconify from "../../components/Iconify";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import CallIcon from '@mui/icons-material/Call';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "In-Payments",
    path: "/remittance/main",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    title: "Transfers",
    path: "/remittance/list",
    icon: <ReceiptIcon />
  },
  {
    title: "How It Works",
    path: "/remittance/how-it-works",
    icon: <SettingsIcon />,
  },
  {
    title: "Contact",
    path: "/remittance/contact",
    icon: <CallIcon/>,
  },
  // {
  //   title: "Deposit Your Account",
  //   path: "/dashboard/deposit",
  //   icon: <ReceiptLongIcon />,
  // },
  // {
  //   title: "books",
  //   path: "/dashboard/book",
  //   icon: getIcon("eva:book-open-outline"),
  // },
  // {
  //   title: "solutions",
  //   path: "/dashboard/solution",
  //   icon: getIcon("ep:document"),
  // },
  // {
  //   title: "categories",
  //   path: "/dashboard/category",
  //   icon: getIcon("bx:category"),
  // },
  // {
  //   title: "waiting list",
  //   path: "/dashboard/waiting-list",
  //   icon: getIcon("medical-icon:i-waiting-area"),
  // },
  // {
  //   title: "search logs",
  //   path: "/dashboard/searchlog",
  //   icon: getIcon("fa-solid:search-plus"),
  // },
  // {
  //   title: "site settings",
  //   path: "/dashboard/site-setting",
  //   icon: getIcon("clarity:settings-solid-badged"),
  // },
];

export default navConfig;
