import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from "formik";
import currencyInstance from 'src/axios/currencyInstance';
import { LoadingButton } from '@mui/lab';
import * as Yup from "yup";
import {
    FormControl,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    Typography,
} from '@mui/material';
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "../../src/css/invoiceImage.css"

const InvoiceForm = (props) => {
    const {balances}=props;
    const [currencies, setCurrencies] = useState([])

    const fetchCurrencies = async () => {
        try {
            const res = await currencyInstance.get(`/remittance`)
            const rates = res.data.remittanceCurrencies
            setCurrencies(rates)
        } catch (error) {
            console.log({ error })
        }
    }
    useEffect(() => {
        fetchCurrencies()
    }, [])

    const InvoiceSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        phoneNo: Yup.string().required("Please enter your phone number"),
        ref: Yup.string(),
        address: Yup.string().required("Please provide your address"),
        currency: Yup.string().required("Currency is required"),
        otherCurrency: Yup.string().when('currency', {
            is: 'other',
            then: Yup.string().required("Other Currency is Required"),
            otherwise: Yup.string()
        }),
        amount: Yup.number().required("Amount is required").positive().integer(),
        paymentMethod: Yup.string().required("Please Select a method"),
        otherMethod: Yup.string().when('paymentMethod', {
            is: 'other',
            then: Yup.string().required("Other Method is Required"),
            otherwise: Yup.string()
        }),
        iban: Yup.string().when('paymentMethod', {
            is: 'IBAN',
            then: Yup.string().required("Iban is Required"),
            otherwise: Yup.string()
        }),
        accountNo: Yup.string().when('paymentMethod', {
            is: 'AccountNumber',
            then: Yup.string().required("Account No. is Required"),
            otherwise: Yup.string()
        }),
        swiftBIC: Yup.string().when('paymentMethod', {
            is: 'AccountNumber',
            then: Yup.string().required("SwiftBIC is Required"),
            otherwise: Yup.string()
        }),
        action: Yup.string()
    })


    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phoneNo: '',
            ref: "",
            address: "",
            currency: "",
            otherCurrency: "",
            amount: "",
            paymentMethod: "",
            iban: "",
            accountNo: "",
            otherMethod: "",
            swiftBIC: "",
            action: 'remittanceInvoice'
        },
        validationSchema: InvoiceSchema,
        onSubmit: async (values, { setErrors }) => {
            const formData = new FormData();
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            formData.append("phoneNo", values.phoneNo)
            formData.append("ref", values.ref)
            formData.append("address", values.address)
            formData.append("currency", values.currency)
            formData.append("otherCurrency", values.otherCurrency)
            formData.append("amount", values.amount)
            formData.append("paymentMethod", values.paymentMethod)
            formData.append("iban", values.iban)
            formData.append("accountNo", values.accountNo)
            formData.append("otherMethod", values.otherMethod)
            formData.append("swiftBIC", values.swiftBIC)
            formData.append("action", values.action)
            try {
                await submittedData(formData);
            } catch (err) {
                setErrors({ error: err.response.data.message });
            }
        },
    })


    const { errors, touched, handleSubmit, handleChange, isSubmitting, values, getFieldProps, setFieldValue } = formik;

    const { submittedData } = props


    return (
        <>
            <React.Fragment>
                <Paper elevation={3} sx={{ marginRight: "1%", marginLeft: "1%" }}>
                    <Box sx={{ padding: 5 }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Payee Information
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="firstName"
                                            label="First Name"
                                            fullWidth
                                            required
                                            value={values.firstName}
                                            onChange={handleChange}
                                            {...getFieldProps("firstName")}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="lastName"
                                            label="Last Name"
                                            fullWidth
                                            required
                                            value={values.lastName}
                                            onChange={handleChange}
                                            {...getFieldProps("lastName")}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="phoneNo"
                                            label="Phone Number"
                                            fullWidth
                                            type='number'
                                            required
                                            value={values.phoneNo}
                                            onChange={handleChange}
                                            {...getFieldProps("phoneNo")}
                                            error={Boolean(touched.phoneNo && errors.phoneNo)}
                                            helperText={touched.phoneNo && errors.phoneNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="ref"
                                            label="Reference"
                                            fullWidth
                                            value={values.ref}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="address"
                                            label="Address"
                                            fullWidth
                                            multiline
                                            required
                                            rows={3}
                                            value={values.address}
                                            onChange={handleChange}
                                            {...getFieldProps("address")}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Typography fontSize={20} fontWeight={700}>
                                            Account Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="currency-label"  {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                required
                                                helperText={touched.currency && errors.currency}>Select Currency</InputLabel>
                                            <Select
                                                labelId="currency-label"
                                                id="currency"
                                                value={values.currency}
                                                onChange={handleChange}
                                                {...getFieldProps("currency")}
                                                error={Boolean(touched.currency && errors.currency)}
                                                helperText={touched.currency && errors.currency}

                                            >
                                                {balances.map((option) => (

                                                    <MenuItem key={option.currency} value={option.currency}>
                                                        {"(" + option.currency + ")"}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="other">Other Currency</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {values.currency === 'other' && (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="otherCurrency"
                                                label="Enter Other Currency"
                                                required
                                                fullWidth
                                                value={values.otherCurrency}
                                                onChange={(e) => {
                                                    setFieldValue("currency", e.target.files[0])
                                                }}
                                                {...getFieldProps("otherCurrency")}
                                                error={Boolean(touched.otherCurrency && errors.otherCurrency)}
                                                helperText={touched.otherCurrency && errors.otherCurrency}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="amount"
                                            label="Enter Amount"
                                            fullWidth
                                            type="number"
                                            required
                                            value={values.amount}
                                            onChange={handleChange}
                                            {...getFieldProps("amount")}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="payment-method-label"
                                                {...getFieldProps("paymentMethod")}
                                                required
                                                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                                                helperText={touched.paymentMethod && errors.paymentMethod}
                                            >Select Payment Method</InputLabel>
                                            <Select
                                                labelId="payment-method-label"
                                                id="paymentMethod"
                                                value={values.paymentMethod}
                                                onChange={handleChange}
                                                {...getFieldProps("paymentMethod")}
                                                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                                                helperText={touched.paymentMethod && errors.paymentMethod}
                                            >
                                                <MenuItem value="IBAN">IBAN</MenuItem>
                                                <MenuItem value="AccountNumber">Account Number</MenuItem>
                                                <MenuItem value="other">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {values.paymentMethod === 'IBAN' && (

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="iban"
                                                label="Enter IBAN"
                                                required
                                                fullWidth
                                                value={values.iban}
                                                onChange={handleChange}
                                                {...getFieldProps("iban")}
                                                error={Boolean(touched.iban && errors.iban)}
                                                helperText={touched.iban && errors.iban}
                                            />
                                        </Grid>
                                    )}
                                    {values.paymentMethod === 'AccountNumber' && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="accountNo"
                                                    label="Enter Account Number"
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    value={values.accountNo}
                                                    onChange={handleChange}
                                                    {...getFieldProps("accountNo")}
                                                    error={Boolean(touched.accountNo && errors.accountNo)}
                                                    helperText={touched.accountNo && errors.accountNo}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="swiftBIC"
                                                    label="Enter SwiftBIC"
                                                    fullWidth
                                                    value={values.swiftBIC}
                                                    required
                                                    onChange={handleChange}
                                                    {...getFieldProps("swiftBIC")}
                                                    error={Boolean(touched.swiftBIC && errors.swiftBIC)}
                                                    helperText={touched.swiftBIC && errors.swiftBIC}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {values.paymentMethod === 'other' && (

                                        <Grid item xs={12}>
                                            <TextField
                                                id="otherMethod"
                                                label="Enter Other Method"
                                                fullWidth
                                                required
                                                rows={3}
                                                value={values.otherMethod}
                                                onChange={handleChange}
                                                {...getFieldProps("otherMethod")}
                                                error={Boolean(touched.otherMethod && errors.otherMethod)}
                                                helperText={touched.otherMethod && errors.otherMethod}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Box textAlign='center'>
                                            <LoadingButton
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}
                                                color="primary"
                                            >
                                                <Typography color="white">
                                                    Submit
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Paper>
            </React.Fragment >
        </>
    )
}

export default InvoiceForm