import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useAlert } from "react-alert";
// material
import {
    Stack,
    Card,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Box,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import authInstance from "src/axios/authInstance";
import { Container, InputLabel } from "@mui/material";
import Page from "src/components/Page";

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

const UpdatePasswordForm = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object({
        currentPassword: Yup.string().required("Please Enter current Password"),
        newPassword: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().test(
            "passwords-match",
            "Passwords must match",
            function (value) {
                return this.parent.newPassword === value;
            }
        ),
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                await authInstance.patch(`/update-password`, {
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                });
                alert.success("Password Changed Successfully")
                navigate("/");
            } catch (error) {
                alert.error(error.response.data.message)
                console.log(error);
                setErrors({ error: error.response.data.message });
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    return (
        <Box>

            <Page title="Update Profile">
                <RootStyle>
                    <Container maxWidth="sm">
                        <ContentStyle>
                            <Typography align="center" variant="h4" marginBottom="3rem" color="#8C30F5">
                                Change Password
                            </Typography>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={3} marginBottom="2rem">
                                        <TextField
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? "text" : "password"}
                                            label="Current Password"
                                            {...getFieldProps("currentPassword")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            <Iconify
                                                                icon={
                                                                    showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.currentPassword && errors.currentPassword)}
                                            helperText={touched.currentPassword && errors.currentPassword}
                                        />
                                        <TextField
                                            fullWidth
                                            autoComplete="new-password"
                                            type={showPassword ? "text" : "password"}
                                            label="New Password"
                                            {...getFieldProps("newPassword")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            <Iconify
                                                                icon={
                                                                    showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.newPassword && errors.newPassword)}
                                            helperText={touched.newPassword && errors.newPassword}
                                        />


                                        <TextField
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            {...getFieldProps("confirmPassword")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            <Iconify
                                                                icon={
                                                                    showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(
                                                touched.confirmPassword && errors.confirmPassword
                                            )}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                        />
                                    </Stack>

                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        Change
                                    </LoadingButton>
                                </Form>
                            </FormikProvider>
                        </ContentStyle>
                    </Container>
                </RootStyle>
            </Page>
        </Box>
    )
}

export default UpdatePasswordForm