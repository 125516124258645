import depositInstance from "src/axios/depositInstance";
import { Grid, Container, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { TableCell, TableBody, TableHead, TableRow, Table, TableContainer, Paper, AppBar, Toolbar, IconButton } from '@mui/material';
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { loadProfile } from "src/store/actions/authActions";
import { Button } from "@mui/material"
import { DialogContent, DialogTitle, Dialog, DialogActions } from '@mui/material'
import PopUp from "./PopUp";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import DepositSlip from "./DepositSlip";
import balancesInstance from "src/axios/balancesInstance";
// import { loadProfile } from "src/store/actions/authActions";
// ----------------------------------------------------------------------
import { useNavigate } from "react-router-dom";
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";
import currencyInstance from "src/axios/currencyInstance";
import Pagination from 'react-js-pagination';
import "../css/pagination.css"
import EditPaymentForm from "./EditPaymentForm";
import Page from "src/components/Page";

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

const styles = {
  appBar: {
    borderRadius: "0 0 10px 10px",
  },
};
export default function DashboardApp() {
  const [loading, setLoading] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [balances, setBalances] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [deposits, setDeposits] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState()
  const [resultPerPage, setResultPerPage] = useState()
  const [toUpdate, setToUpdate] = useState()
  const [slug, setSlug] = useState()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate()

  const handleRowClick = (slug) => {
    navigate(`/remittance/deposit-detail/${slug}`)
  }
  const setCurrentPageNo = (e) => {
    setCurrentPage(e)
  }
  const handleBtn = () => {
    setopenPopup(true)
  }
  const handleStop = (event) => {
    event.stopPropagation();
  };

  const editDeposit = async (slug) => {
    try {
      setSlug(slug)
      const response = await depositInstance.get(`/${slug}`);
      setToUpdate(response.data.remitDeposit)
      setOpen(true)
    } catch (err) {
      alert.error(err.response.data.message)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const updatePayment = async (slug, formData) => {
    try {
      await depositInstance.put(`/payment/${slug}`, formData)
      setOpen(false)
      fetchData()
      alert.success("Edited Sucessfully")

    } catch (error) {
      alert.error(error.response.data.message)

    }
  }

  const fetchCurrencies = async () => {
    try {
      const res = await currencyInstance.get(`/remittance`)
      const rates = res.data.remittanceCurrencies
      setCurrencies(rates)
    } catch (error) {
      console.log({ error })
    }
  }

  const fetchDeposits = async () => {
    try {
      const response = await depositInstance.get(`/?page=${currentPage}`);
      const count = response.data.remitDepositCount
      setCount(count)
      const resultPerPage = response.data.resultPerPage
      setResultPerPage(resultPerPage)
      const data = response.data.remitDeposits;
      setDeposits(data)
    } catch (error) {
      console.error({ error })
    }
  }
  const fetchBalances = async () => {
    try {
      const response = await balancesInstance.get(`/remit-user`);
      const data = response.data.userBalance;
      setBalances(data)
    } catch (error) {
      console.log({ error })
    }
  }

  const fetchData = async () => {
    setLoading(true)
    await fetchDeposits()
    await fetchCurrencies()
    await fetchBalances()
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
    dispatch(loadProfile())
  }, [currentPage])

  const submittedData = async (data) => {
    try {
      const response = await depositInstance.post(`/`, data)
      console.log(response.data.invoice)
      alert.success("Deposit Created")
      setopenPopup(false)
      fetchDeposits()
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }

  const deleteDepositHandler = async (slug) => {
    try {
      const response = await depositInstance.put(`/${slug}`)
      alert.success("Payment Cancelled")
      fetchDeposits()
    } catch (error) {
      alert.error(error.response.data.message)
    }
  };

  if (loading) {
    return <LoaderWrapper>
      <CircularProgress size={80} />
    </LoaderWrapper>
  }
  return (
    <Page title="ExSquares">
      <Container maxWidth="xl">
        <Grid id='balances-cards' container spacing={3}>
          {(balances.length > 0) &&
            balances?.map((data) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{
                    cursor: "pointer",
                  }
                  }
                // onClick={() => setEditOpen(true)}
                >
                  <AppWidgetSummary
                    title={`${data?.currency} balances`}
                    total={data?.balanceValue}
                    icon={(data?.currency === 'GBP') ? "circle-flags:uk" : (data?.currency === 'USD') ? "circle-flags:us" : (data?.currency === 'EUR') ? "circle-flags:eu" : (data?.currency === 'SDG') ? "emojione:flag-for-sudan" : 'grommet-icons:currency'}
                    color='success'
                  />
                </Grid>
              )
            })
          }
        </Grid>
        <Button onClick={handleBtn} style={{ float: "right", marginBottom: "1rem" }} variant="contained">Add Payment</Button>
        <PopUp title="Payment Slip" openPopup={openPopup} setopenPopup={setopenPopup}>
          <DepositSlip balances={balances} submittedData={submittedData} currencies={currencies} />
        </PopUp>
        <AppBar style={styles.appBar} position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              Payments List
            </Typography>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Created Date</TableCell>
                <TableCell align="center">Action</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {deposits.map((row) => (
                <TableRow
                  hover
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => { handleRowClick(row.slug) }}
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{row.amount}{'(' + row.currency + ')'}</TableCell>
                  <TableCell style={{ color: (row.status === 'Approved') ? 'green' : (row.status === 'Cancelled') ? 'red' : (row.status === 'Paid') ? 'blue' : 'black' }} align="center">{row.status}</TableCell>
                  <TableCell align="right">{row.createdAt.substr(0, 10)}</TableCell>
                  <TableCell onClick={handleStop} align="center">
                    <Button disabled={(row.status === 'Pending') ? false : true} onClick={() => editDeposit(row.slug)}><EditIcon /></Button>
                    <Button disabled={(row.status === 'Cancelled' || row.status !== 'Pending') ? true : false} onClick={() => deleteDepositHandler(row.slug)} color="warning" ><CancelIcon /></Button>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {resultPerPage < count &&
          <div className="paginationBox">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={count}
              onChange={setCurrentPageNo}
              nextPageText="Next"
              prevPageText="Prev"
              firstPageText="1st"
              lastPageText="Last"
              itemClass='page-item'
              linkClass='page-link'
              activeClass='pageItemActive'
              activeLinkClass='pageLinkActive'
            />
          </div>
        }
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Edit Payment
          <DialogContent>
            <EditPaymentForm updatePayment={updatePayment} toUpdate={toUpdate} currencies={currencies} slug={slug} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>

    </Page>
  );
}
