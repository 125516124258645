import React from 'react'
import "../css/HowItWorks.css"

const HowItWorks = () => {
  return (
    <div className='top'>
    <div className="header">
        <h1>How it works</h1>
    </div>
    <div className="container">
        <div className="sub-heading">
           Your Dashboard
        </div>
        <div className='card'>
        <p>• In your dashboard you can see your updated cash balances and navigate to In-Payments and Transfers tools.</p>
        </div>
        <div className="arrow1"/>
        <div className="sub-heading">
            In-Payments
        </div>
        <div className='card'>
        <p>• Here you can make payments into your account. Click on Add Payment, a pop-up window will then appear where you can fill in the required information and upload your proof of payment slip.</p>
        <p>• Once these were submitted the payment will go through our verification process, please allow some time for your payment to be reflected in your account balance.</p>
        </div>
        <div className="arrow1"/>
        <div className="sub-heading">
            Transfers
        </div>
        <div className='card'>
        <p>• Here is where you instruct us with transfers to your clients. Click on Make a Transfer, a pop-up window will appear where you can fill in your payee information as well as how you would like them to get paid by choosing the most appropriate option for payment method. </p>
        <p>• Once you have submitted these, they will go through verification and processing steps, and these will be shown to you on the Transfers page. </p>
        <p>• The Transfers page shows you all transfer requests made. Here you can see all their details, status, and actions you could make on them. </p>
        <p>• The Status shows one of 3 statuses. The first is Pending, this will be the first step when your instructions are received. Once reviewed it will then either be Approved or Cancelled. If cancelled, we will then send you an email to tell you why. If approved, the payment will be made to your payee. Once this is done a proof of payment to your payee will be visible to you and the payment status will change to Paid. </p>
        </div>
    </div>
    </div>
  )
}

export default HowItWorks