import React from 'react'
import { makeStyles } from "@mui/styles"
import { useState } from 'react';
import { Grid,Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    profileContainer: {
      textAlign: 'center',
      padding: '20px',
      backgroundColor:'#F4F5F7',
    },
    centeredHeading: {
      fontSize: '44px',
      fontWeight: 'bold',
      marginBottom: '10px',
      marginTop:"70px",
      fontFamily: "Manrope, sans-serif",
      letterSpacing:"2px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
      },
    },
    subheading: {
      fontSize: '18px',
      fontFamily: "Manrope, sans-serif",
      color: '#888',
      marginBottom: '20px',
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    profiles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', 
      marginBottom:"100px",
      marginTop:"100px"
    },
    profile: {
      margin: '20px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',  
      marginBottom:'100px',
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
    },
    profileImage: {
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    profileName: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '10px',
    },
    bubble: {
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      padding: '10px',
      position: 'absolute',
      bottom: '-50px',
      left: '50%',
      transform: 'translateX(-50%)',
      minWidth: '200px',
      zIndex: '1',
    },
    thinkingCard: {
        backgroundColor: '#fff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '-100px',
        zIndex: '1',
      },
      thinkingHeading: {
        fontSize: '22px',
        fontWeight: 'bold',
        fontFamily: "Manrope, sans-serif",
      },
      thinkingSubheading: {
        marginTop:"10px",
        fontSize: '16px',
        color: '#888',
      },
      profileContainerHovered: {
        '&:hover $card': {
          display: 'block',
        },
      },
  }));

  const profiles = [
    {
      id: 1,
      name: 'Jane Cooper',
      imageSrc: 'profile1.jpg',
      subheading: 'Project Manager',
      thinking: {
        heading: 'Efficient Collaborating',
        subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.',
      },
    },
    {
      id: 2,
      name: 'John Doe',
      imageSrc: 'profile2.jpg',
      subheading: 'Software Engineer',
      thinking: {
        heading: 'Innovative Problem Solving',
        subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.',
      },
    },
    {
      id: 3,
      name: 'Alice Johnson',
      imageSrc: 'profile3.jpg',
      subheading: 'Graphic Designer',
      thinking: {
        heading: 'Creative Designing',
        subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.',
      },
    },
  ];

const OurClientSpeaks = () => {
    const classes = useStyles();
    const [showBubble, setShowBubble] = useState(false);
  return (
    <Grid container spacing={3} className={classes.profileContainer}>
    <Grid item xs={12}>
      <Typography variant="h1" align="center" className={classes.centeredHeading}>
        Our Client Speaks
      </Typography>
      <Typography variant="h2" align="center" className={classes.subheading}>
        We have been working with clients around the world
      </Typography>
    </Grid>
    {profiles.map((profile) => (
      <Grid item xs={12} sm={6} md={4} key={profile.id}>
        <div className={classes.profile}>
          <div className={classes.thinkingCard}>
            <Typography variant="h3" className={classes.thinkingHeading}>
              {profile.thinking.heading}
            </Typography>
            <Typography variant="p" className={classes.thinkingSubheading}>
              {profile.thinking.subheading}
            </Typography>
          </div>
          <img
            style={{ marginTop: '20px' }}
            src="/static/Picture.png"
            alt={profile.name}
            onClick={() => setShowBubble(true)}
          />
          <Typography variant="h4">{profile.name}</Typography>
          <Typography variant="span" style={{ fontSize: '12px' }}>
            CEO at ABC Corporation
          </Typography>
        </div>
      </Grid>
    ))}
  </Grid>
    // <div className={classes.profileContainer}>
    //   <h1 className={classes.centeredHeading}>Our Client Speaks</h1>
    //   <h2 className={classes.subheading}>We have been working with clients around the world</h2>
    //   <div className={classes.profiles}>
    //     {profiles.map((profile) => (
    //       <div key={profile.id} className={classes.profile}>
    //        <div className={classes.thinkingCard}>
    //         <h3 className={classes.thinkingHeading}>{profile.thinking.heading}</h3>
    //         <p className={classes.thinkingSubheading}>{profile.thinking.subheading}</p>
    //       </div>
    //         {/* <div className={classes.profileImage}> */}
    //         <img
    //             style={{marginTop:"20px"}}
    //             src="/static/Picture.png"
    //             alt={profile.name}
    //             onClick={()=>setShowBubble(true)}
    //           />
    //         {/* </div> */}
    //         <h4>{profile.name}</h4>
    //         <span style={{fontSize:'12px'}}>CEO at ABC Corperation</span>
            
    //       </div>
    //     ))}
    //   </div>
    // </div>
  )
}

export default OurClientSpeaks