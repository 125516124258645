import React, { useEffect } from 'react'
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { Container, Input, InputLabel, Typography } from "@mui/material";
import Page from "../components/Page";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, Form, FormikProvider } from "formik";
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    FormHelperText,
    Select,
    MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import authInstance from 'src/axios/authInstance';

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

const UserProfile = () => {
    const { firstName, lastName, email, phoneNo, bio } = useSelector(state => state.auth)

    // useEffect(() => {
    //     if (firstName && lastName && email && phoneNo) {
    //         setFieldValue("firstName", firstName);
    //         setFieldValue("lastName", lastName);
    //         setFieldValue("email", email);
    //         setFieldValue("phoneNo", phoneNo);
    //     }
    // }, [firstName, lastName, email, phoneNo]);


    const alert = useAlert();
    const updateProfile = async (data) => {
        try {
            const response = await authInstance.patch(`/edit-user`, data)
            console.log({ updated: response.data })
            setFieldValue("firstName", response.firstName);
            setFieldValue("lastName", response.lastName);
            setFieldValue("email", response.email);
            setFieldValue("phoneNo", response.phoneNo);
            setFieldValue("bio", response.bio);
            alert.success("Updated Successfully")
        } catch (error) {
            alert.error(error.response.data.message)
        }

    }

    const profileSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        bio: Yup.string(),
        phoneNo: Yup.string(),
        email: Yup.string()
    })

    const formik = useFormik({
        initialValues: {
            firstName: `${firstName}`,
            lastName: `${lastName}`,
            bio: `${bio}`,
            email: `${email}`,
            phoneNo: `${phoneNo}`,
        },
        validationSchema: profileSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                // const myForm = new FormData();
                // myForm.append("firstName", values.firstName)
                // myForm.append("lastName", values.lastName)
                // myForm.append("phoneNo", values.phoneNo)
                // myForm.append("bio", values.bio)
                // console.log({ Lname: values.lastName })
                const data = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNo: values.phoneNo,
                    bio: values.bio,
                }
                await updateProfile(data)


            } catch (err) {
                setErrors({ error: err.response.data.message });
            }
        },
    })

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
        formik;

    return (
        <Page title="Update Profile">
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography align="center" variant="h4" marginBottom="3rem" color="#8C30F5">
                            Update Profile
                        </Typography>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <Stack direction="row" spacing={22}>
                                        <InputLabel>First Name</InputLabel>
                                        <InputLabel>Last Name</InputLabel>


                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            autoComplete="firstName"
                                            fullWidth
                                            type="text"
                                            {...getFieldProps("firstName")}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                        <TextField
                                            autoComplete="lastName"
                                            type="text"
                                            fullWidth
                                            {...getFieldProps("lastName")}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={22}>
                                        <InputLabel>Bio</InputLabel>
                                    </Stack>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        {...getFieldProps("bio")}
                                        error={Boolean(touched.bio && errors.bio)}
                                        helperText={touched.bio && errors.bio}
                                    >
                                    </TextField>
                                    <Stack direction="row" spacing={22}>
                                        <InputLabel>Phone No.</InputLabel>
                                        <InputLabel>Email</InputLabel>


                                    </Stack>

                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            autoComplete="username"
                                            type="number"
                                            {...getFieldProps("phoneNo")}
                                            error={Boolean(touched.phoneNo && errors.phoneNo)}
                                            helperText={touched.phoneNo && errors.phoneNo}
                                        />
                                        <TextField
                                            variant="standard"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            autoComplete="username"
                                            type="email"
                                            value={values.email}
                                            style={{
                                                marginTop: "1.2rem"
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <br />
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Update
                                </LoadingButton>
                            </Form>
                        </FormikProvider>
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    )
}

export default UserProfile