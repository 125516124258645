export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"

export const NEW_INVOICE_REQUEST = "NEW_INVOICE_REQUEST"
export const NEW_INVOICE_FAIL = "NEW_INVOICE_FAIL"
export const NEW_INVOICE_SUCCESS = "NEW_INVOICE_SUCCESS"
export const NEW_INVOICE_RESET = "NEW_INVOICE_RESET"

export const NEW_DEPOSIT_REQUEST = "NEW_DEPOSIT_REQUEST"
export const NEW_DEPOSIT_FAIL = "NEW_DEPOSIT_FAIL"
export const NEW_DEPOSIT_SUCCESS = "NEW_DEPOSIT_SUCCESS"
export const NEW_DEPOSIT_RESET = "NEW_DEPOSIT_RESET"

export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST"
export const UPDATE_INVOICE_FAIL = "UPDATE_INVOICE_FAIL"
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS"
export const UPDATE_INVOICE_RESET = "UPDATE_INVOICE_RESET"

export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";

export const GET_DEPOSIT_SUCCESS = "GET_DEPOSIT_SUCCESS";
export const GET_DEPOSIT_FAIL = "GET_DEPOSIT_FAIL";
export const GET_DEPOSIT_REQUEST = "GET_DEPOSIT_REQUEST";

export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_FAIL = "DELETE_INVOICE_FAIL";
export const DELETE_INVOICE_RESET = "DELETE_INVOICE_RESET";



export const CLEAR_ERRORS = "CLEAR_ERRORS"