import * as React from "react";
import {
  Box,
  List,
  Drawer,
  Divider,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Twirl as Hamburger } from "hamburger-react";

const NavLinks = [
  {
    id: 1000,
    title: "Login",
    href: '/login'
  },
  {
    id: 1001,
    title: "Register",
    href: '/signup'
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 2px #E5E9F2",
    backgroundColor: "#ffffff",
  },
  drawerContainer: {
    width: "100%",
    height: "100%",
    padding: "2rem 0",
    position: "relative",
  },
  title: {
    // ...theme.typography.mavenPro,
    // color: "#D91BFA",
    // cursor: "pointer",
    // fontSize: "1.5rem",
    // marginBottom: "1.7rem",
    // marginLeft: "1.3rem",
    width:'150px',
    height:'150px'
  },
  menuItems: {
    cursor: "pointer",
    padding: ".5rem",
  },
  linkStyles: {
    color: "black",
    fontSize: "1.062rem",
  },
  searchContainer: {
    marginTop: "1.8rem",
    padding: ".2rem 1.3rem",
    width: "100%",
  },
  searchButton: {
    width: "100%",
    marginTop: "1rem",
  },
  drawerFooter: {
    height: "60px",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  footerText: {
    fontSize: ".8rem",
    color: "black",
    margin: "1.1rem 1.3rem",
  },
  postQuestionModel: {
    marginTop: "1.5rem",
  },
  navLink: {
    textDecoration: "none",
    color: 'black' ,
    "&:hover": {
      color: 'white'
    },
  },
}));

export default function MenuDrawer() {
  const classes = useStyles();

  const [toggle, setToggle] = React.useState(false);

  const list = () => (
    <Box className={classes.drawerContainer}>
      <Box>
        <Link to="/">
          <img src="/static/logox.png" alt="ss"/>
        </Link>
        <Divider />
        <Box className={classes.menuItems}>
          <List>
            {NavLinks?.map((value) => {
              return (
                <Link className={classes.navLink} to={value?.href} key={value.id}>
                  <ListItem
                    disablePadding
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    <ListItemButton>
                      <Typography
                        color="textSecondary"
                        // className={classes.linkStyles}
                      >
                        {value?.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
          <Divider />
                </Link>
              );
            })}
          </List>
        </Box>

      </Box>
      <Box className={classes.drawerFooter}>
        <Divider />
        <Typography className={classes.footerText}>Powered by KBM</Typography>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hamburger
        className={classes.hamburger}
        color="black"
        size={23}
        toggled={toggle}
        toggle={() => setToggle(!toggle)}
      />
      <Drawer
        open={toggle}
        onClose={() => setToggle(false)}
        PaperProps={{ sx: { width: "70vw" } }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
